import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'LBD',
    tokenAddress: '0xB4025E2a8A0A5F85bd1Bf38e44F9eEd74B08aA59',   // token address
    stakingTokenName: QuoteToken.LBD,
    stakingTokenAddress: '0xB4025E2a8A0A5F85bd1Bf38e44F9eEd74B08aA59',  // token address
    stakingTokenDecimals: 9,
    contractAddress: {
      97: '0x8e2a1A747128fC9e6239A7773d45789e257b58F4', // testnet (TTOKEN)
      56: '0x0d294E5E3b26F73a1e8eeaf054951Ccb51A50684',  // masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '64300411.5226',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 9,
  },
 
]

export default pools
