import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xf67006f8d22edEbd1191f3E728ab9a90289e6C38',
      },
      {
        label: 'Liquidity',
        href: 'https://pancakeswap.finance/add/0xf67006f8d22edEbd1191f3E728ab9a90289e6C38/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  /* {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  }, */
  {
    label: 'NFTs',
    icon: 'NftIcon',
    href: '/collectibles',
  },
  {
    label: 'Change The World',
    icon: 'GroupsIcon',
    href: 'https://littlebabydoge.com/change_world',
  },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://Littlebabydoge.app/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://Littlebabydoge.app/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://Littlebabydoge.app/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  {
    label: 'Presale',
    icon: 'IfoIcon',
    href: 'https://lbd-presale.netlify.app/',
  },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Voting',
  //       href: 'https://voting.Littlebabydoge.app',
  //     },
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://docs.Littlebabydoge.app',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://pancakeswap.medium.com',
  //     },
  //   ],
  // },
]

export default config
