export default {
  cake: { // LBD token, not cake.. what the heck.. 
    97: '0xB4025E2a8A0A5F85bd1Bf38e44F9eEd74B08aA59', // TTOKEN
    56: '0xf67006f8d22edebd1191f3e728ab9a90289e6c38',
  },
  syrup: {
    97: '',
    56: '0xd6f1EFAc4b5e468fE75CB2A7086011B153792BF9',
  },
  masterChef: {
    97: '0x8e2a1A747128fC9e6239A7773d45789e257b58F4',
    56: '0x0d294E5E3b26F73a1e8eeaf054951Ccb51A50684',
  },
  sousChef: {
    97: '',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  wbnb: {
    97: '',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  busd: {
    97: '',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  eth: {
    97: '',
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  },
  btc: {
    97: '',
    56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  },
  ada: {
    56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '',
  },
  lottery: {
    97: '',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '',
  },
}
