import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'LBD',
    lpAddresses: {
      97: '0xB4025E2a8A0A5F85bd1Bf38e44F9eEd74B08aA59',
      56: '0x23780F87D84882FC3FbfE9f9eC682441e9E9EB93',
    },
    tokenSymbol: 'LBD',
    tokenAddresses: {
      97: '0xB4025E2a8A0A5F85bd1Bf38e44F9eEd74B08aA59',
      56: '0x23780F87D84882FC3FbfE9f9eC682441e9E9EB93',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

   {
    pid: 1,
    lpSymbol: 'LBD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x34c17f6eA94202A3c99B9e708818CaE9a151f197',   // lp address token-bnb
    },
    tokenSymbol: 'LBD',
    tokenAddresses: {
      97: '',
      56: '0xf67006f8d22edebd1191f3e728ab9a90289e6c38', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  }, 
]

// BNB / BUSD: 0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16
// ETH / BNB: 0x74E4716E431f45807DCF19f284c7aA99F18a4fbc

export default farms
