import { Nft } from './types'

const Nfts: Nft[] = [
  {
    name: 'Beach Ball',
    description: "Little Baby Doge having fun on the beach.",
    images: {
      lg: 'Beach Ball.jpg',
      md: 'hiccup-md.png',
      sm: 'hiccup-sm.png',
      ipfs: 'https://bafybeifglc5kojsfr3iq27dw4jo7ffd4r4ofer4rmdzintjqjh4dpp4lne.ipfs.infura-ipfs.io/',
    },
    sortOrder: 999,
    bunnyId: 10,
  },
  {
    name: 'Climate Hero',
    description: "Little Baby Doge and Greta Thunberg the climate hero.",
    images: {
      lg: 'Climate Hero.gif',
      md: 'hiccup-md.png',
      sm: 'hiccup-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmQ6EE6gkVzAQUdQLLM7CyrnME6LZHCoy92ZERW8HXmyjw/hiccup.png',
    },
    sortOrder: 999,
    bunnyId: 10,
  },
  {
    name: 'Forest is Future',
    description: 'Little Baby Doge in the Forest',
    images: {
      lg: 'Forest is Future.gif',
      md: 'sleepy-md.png',
      sm: 'sleepy-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/sleepy.png',
      blur: 'sleepy-blur.png',
    },
    sortOrder: 999,
    bunnyId: 5,
  },
  {
    name: 'Greta Thunberg and Leonardo DiCaprio',
    description: 'Two heroes of the old and new era.',
    images: {
      lg: 'Greta Thunberg _ Leonardo DiCaprio.jpg',
      md: 'sunny-md.png',
      sm: 'sunny-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/sunny.png',
      blur: 'sunny-blur.png',
    },
    sortOrder: 999,
    bunnyId: 9,
  },
  {
    name: 'LBD and Greta',
    description: "Little Baby Doge and Greta",
    images: {
      lg: 'LBD and Greta.jpg',
      md: 'churro-md.png',
      sm: 'churro-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/churro.png',
      blur: 'churro-blur.png',
    },
    sortOrder: 999,
    bunnyId: 8,
  },
  {
    name: 'Love for Nature',
    description: "Little Baby Doge enjoying himself in the nature.",
    images: {
      lg: 'Love for Nature.gif',
      md: 'dollop-md.png',
      sm: 'dollop-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/dollop.png',
      blur: 'dollop-blur.png',
    },
    sortOrder: 999,
    bunnyId: 6,
  },
  {
    name: 'Surf on the Wave',
    description: "Little Baby Doge surfing on the waves.",
    images: {
      lg: 'Surf on the Wave.jpg',
      md: 'twinkle-md.png',
      sm: 'twinkle-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/twinkle.png',
      blur: 'twinkle-blur.png',
    },
    sortOrder: 999,
    bunnyId: 7,
  },
  {
    name: 'The Angel of Earth',
    description: 'Little Baby Doge the angel of the earth.',
    images: {
      lg: 'The Angel of Earth.jpg',
      md: 'swapsies-md.png',
      sm: 'swapsies-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/swapsies.png',
      blur: 'swapsies-blur.png',
    },
    sortOrder: 999,
    bunnyId: 0,
  },
  {
    name: 'Waterfall',
    description: "Little Baby Doge relaxing in a waterfall.",
    images: {
      lg: 'Waterfall.jpg',
      md: 'drizzle-md.png',
      sm: 'drizzle-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/drizzle.png',
      blur: 'drizzle-blur.png',
    },
    sortOrder: 999,
    bunnyId: 1,
  },
  {
    name: 'We Are One',
    description: "Little Baby Doge loves the community.",
    images: {
      lg: 'We Are One.jpg',
      md: 'blueberries-md.png',
      sm: 'blueberries-sm.png',
      ipfs: 'https://gateway.pinata.cloud/ipfs/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/blueberries.png',
      blur: 'blueberries-blur.png',
    },
    sortOrder: 999,
    bunnyId: 2,
  }
]

export default Nfts
